var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"w-100 for-margin-top"},[_c('filter-stores',{staticClass:"d-flex",on:{"changeLayout":_vm.changeLayout}},[_c('div',{staticClass:"d-flex justify-content-between flex-grow-1 w-100"},[_c('h3',{staticClass:"font-weight-bold heading"},[_vm._v(_vm._s(_vm.$t("main.products")))])])]),(_vm.loadingProducts)?_c('div',{staticClass:"d-flex align-items-center justify-content-center mt-5"},[_c('b-spinner',{attrs:{"large":"","type":"grow","variant":"primary"}})],1):[(_vm.allProducts.length)?_c('b-row',_vm._l((_vm.allProducts),function(product,key){return _c('b-col',{key:key,class:[
            {
              'col-xl-4 col-lg-6 col-12 mb-4 active':
                _vm.selectedView === 'productCard',
              'col-md-12': _vm.selectedView !== 'productCard',
            },
          ]},[_c('transition',{attrs:{"name":"router-anim","enter-active-class":`animated zoomIn`,"mode":"out-in","leave-active-class":`animated zoomOut`}},[_c(_vm.selectedView,{tag:"component",attrs:{"productItem":product,"indexOfItem":key + 1}})],1)],1)}),1):_c('div',[_c('not-found-com')],1)]],2),_c('app-pagination',{attrs:{"meta":_vm.meta},on:{"input":_vm.getAllProducts}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }