<template>
  <div>
    <div class="w-100 for-margin-top">
      <filter-stores class="d-flex" @changeLayout="changeLayout">
        <div class="d-flex justify-content-between flex-grow-1 w-100">
          <h3 class="font-weight-bold heading">{{ $t("main.products") }}</h3>
        </div>
      </filter-stores>
      <div
        v-if="loadingProducts"
        class="d-flex align-items-center justify-content-center mt-5"
      >
        <b-spinner large type="grow" variant="primary"></b-spinner>
      </div>
      <template v-else>
        <b-row v-if="allProducts.length">
          <b-col
            v-for="(product, key) in allProducts"
            :class="[
              {
                'col-xl-4 col-lg-6 col-12 mb-4 active':
                  selectedView === 'productCard',
                'col-md-12': selectedView !== 'productCard',
              },
            ]"
            :key="key"
          >
            <transition
              name="router-anim"
              :enter-active-class="`animated zoomIn`"
              mode="out-in"
              :leave-active-class="`animated zoomOut`"
            >
              <component
                :productItem="product"
                :indexOfItem="key + 1"
                :is="selectedView"
              ></component>
            </transition>
          </b-col>
        </b-row>
        <div v-else>
          <not-found-com />
        </div>
      </template>
    </div>
    <app-pagination :meta="meta" @input="getAllProducts" />
  </div>
</template>

<script>
import storeCategories from "@/modules/servicePages/marketplace/components/profile/storeCategories";
import productCard from "@/modules/servicePages/marketplace/components/productCard";
import filterStores from "@/modules/servicePages/marketplace/components/filterMarketPlace";
import profileProductsList from "@/modules/servicePages/marketplace/components/profile/profileProductsList";
import marketPlace from "@/modules/servicePages/marketplace/services/marketplace";
import notFoundCom from "@/modules/servicePages/marketplace/components/notFoundCom";
import AppPagination from "../../../../../components/appPagination.vue";

export default {
  name: "productsCategories",
  // components: { categoriesSwiper, filterStores, productCard, profileProductsList },
  components: {
    storeCategories,
    filterStores,
    productCard,
    profileProductsList,
    notFoundCom,
    AppPagination,
  },
  data() {
    return {
      selectedView: "productCard",
      loadingProducts: true,
      allProducts: [],
      selectSort: "DESC",
      selectedCat: "",
      meta: {},
      sort: [
        {
          name: "من الأحدث للأقدم",
          value: "ASC",
        },
        {
          name: "من الأقدم للأحدث",
          value: "DESC",
        },
      ],
    };
  },

  methods: {
    changeLayout(layout) {
      if (layout === "vertical") {
        this.selectedView = "productCard";
      } else {
        this.selectedView = "profileProductsList";
      }
    },
    selectSortData(selectSort) {
      this.selectSort = selectSort.value;
      this.getAllProducts((this.push = false));
    },

    getAllProducts(page = 1) {
      this.loadingProducts = true;
      marketPlace
        .getAllProducts(
          this.$route.params.id,
          this.selectSort,
          this.$route.query.filter,
          page
        )
        .then((res) => {
          this.allProducts = res.data.data;
          this.meta = res.data.meta;
        })
        .finally(() => {
          this.loadingProducts = false;
        });
    },
  },
  watch: {
    $route: {
      handler: function (route) {
        this.getAllProducts();
      },
      deep: true,
    },
  },

  created() {
    this.getAllProducts();
  },
};
</script>

<style lang="scss" scoped>
.card {
  border-radius: 2px !important;
}

.min-filter {
  width: 20%;
}

.heading {
  font-size: 1.4rem !important;
  color: #212529 !important;
}

@media (max-width: 768px) {
  .min-filter {
    width: 100%;
  }
}
</style>
